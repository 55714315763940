// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $ClientApp-primary: mat.define-palette(mat.$indigo-palette);
// $ClientApp-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
// $ClientApp-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// $ClientApp-theme: mat.define-light-theme(
//   (
//     color: (
//       primary: $ClientApp-primary,
//       accent: $ClientApp-accent,
//       warn: $ClientApp-warn,
//     ),
//   )
// );

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-component-themes($ClientApp-theme);

@import "assets/scss/variables";

/* You can add global styles to this file, and also import other style files */
$page_max_size: 1200px;

@for $i from 1 through 4 {
  .bg-primary.bg-light-#{$i} {
    background-color: lighten($color-primary, $i * 7%) !important;
  }
  .bg-success.bg-light-#{$i} {
    background-color: lighten($color-success, $i * 7%) !important;
  }
  .bg-warning.bg-light-#{$i} {
    background-color: lighten($color-warning, $i * 7%) !important;
  }
  .bg-danger.bg-light-#{$i} {
    background-color: lighten($color-danger, $i * 7%) !important;
  }
}

$header_height: 45px;
$menu_padding: 8px;
$footer_height: 37px;

main {
  max-width: $page_max_size;
  margin: auto;

  .main-container {
    height: calc(100svh - 128px);
    overflow: scroll;
  }

  @media (min-width: 768px) {
    .main-container {
      min-height: calc(100vh - 12px);
    }
  }
}

.txt-bold {
  font-weight: bold;
}

.splice-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media screen and (max-width: 991px) {
  .show-on-lg {
    display: none;
  }
}

.avatar > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.no-outer-border {
  border-left: 0 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;

  &:last-child {
    border-right: 0 !important;
  }
}

a[disabled] {
  pointer-events: none;
}

.spinner-grow {
  width: 1em !important;
  height: 1em !important;
}

.pagination-wrapper {
  overflow-x: auto;
}

@media (min-width: 768px) {
  .pagination-wrapper {
    max-width: calc(100% - 202px);
  }
}
// ng-select
.ng-select {
  .ng-select-container {
    min-height: 24px !important;
    height: 24px !important;
    border: none !important;

    .ng-value-container {
      padding-left: 0 !important;

      .ng-value {
        .ng-value-label {
          .small {
            display: none;
          }
        }
      }

      .ng-input {
        top: 6px !important;
        left: 5px !important;
        padding-left: 0 !important;

        input {
          cursor: text !important;
        }
      }
    }
  }

  .ng-dropdown-panel {
    border: none !important;
  }
}
.ng-select {
  height: 50px !important;

  .ng-select-container {
    border-radius: 8px !important;
    border: 1px solid #d0d5dd !important;
    height: 40px !important;

    .ng-value-container {
      padding-left: 12px !important;

      .ng-value {
        border-radius: 6px !important;
        border: 1px solid #d0d5dd !important;
        background-color: white !important;

        .ng-value-icon {
          border: 0px !important;
        }
      }
    }
  }
}
app-datalist-select.app-datalist-select-double-height {
  .ng-select {
    height: calc(8rem + 4px);
    .ng-select-container {
      height: calc(5.75rem + 4px) !important;
      align-items: flex-start;
    }
  }
}

.form-floating.is-invalid .ng-select .ng-select-container {
  border: 1px solid #fda29b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #fee4e2;
}

app-users-datalist-select {
  .ng-select {
    height: 50px !important;

    .ng-select-container {
      border-radius: 8px !important;
      border: 1px solid #d0d5dd !important;
      height: 40px !important;

      .ng-value-container {
        padding-left: 12px !important;

        .ng-value {
          border-radius: 6px !important;
          border: 1px solid #d0d5dd !important;
          background-color: white !important;

          .ng-value-icon {
            border: 0px !important;
            padding-right: 2px !important;
          }
        }
      }
    }
  }
}

.bs-tooltip-start.tooltip {
  padding-top: 9px;
}
// Static menu version of the content
$menu_open_size: 280px;
$menu_small_size: 280px;

@media (min-width: 768px) {
  body.has-expandable-menu {
    &.menu-expanded {
      overflow: auto;
    }

    app-headermenu {
      app-user-card {
        display: none;
      }
    }

    .sidebar-wrap {
      left: 0;
      opacity: 1;
      width: $menu_open_size;

      .sidebar {
        left: 0;
        width: $menu_open_size;
      }
    }

    main.home-layout {
      padding-left: $menu_open_size;
      max-width: none;
    }

    app-staticfooter,
    app-headermenu {
      padding-left: $menu_open_size;
    }

    &:not(.menu-expanded) {
      .sidebar-wrap {
        width: $menu_small_size;

        .sidebar {
          width: $menu_small_size;

          .nav {
            .dropdown-menu {
              min-width: 0;
              padding: 0;
            }

            .nav-link {
              .icon {
                margin-right: 0;
              }
            }
          }
        }
      }

      main.home-layout {
        padding-left: $menu_small_size;
      }

      app-staticfooter,
      app-headermenu {
        padding-left: $menu_small_size;
      }
    }
  }
}

.dark-mode tspan {
  fill: #888888;

  &:first-child {
    fill: #ffffff;
  }
}

.top-20 {
  margin-top: -15px !important;
}

.dark-mode .log-information.bubble-sheet li p {
  background: #4b4d4e;
}

.dark-mode .log-information.bubble-sheet li p:before {
  border-right: 10px solid #4b4d4e;
}

.dark-mode .ng-select .ng-select-container {
  background-color: #000000;
  color: #ffffff;
}

.log-information {
  & > li {
    padding-right: 0;
  }
}

.dark-mode .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #000000;
  color: #ffffff;
}

.dark-mode .ng-option.ng-option-marked {
  background-color: #000000 !important;
  color: #7297f8 !important;
}

.dark-mode .ng-option.ng-option-selected {
  background-color: #4b4d4e !important;
  color: #ffffff !important;
}

.dark-mode .ng-select .ng-select-container {
  background-color: #000000 !important;
  color: #ffffff;
}
// full calendar
.fc-event-title-container {
  height: 5px !important;
}

.fc-daygrid-day-frame {
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  justify-content: space-between;
}

.fc-daygrid-day-top {
  margin-top: 10px !important;
}

.fc-daygrid-day-bottom {
  display: none !important;
}

.fc-daygrid-day-events {
  min-height: 10px !important;
  overflow: hidden !important;
  overflow-x: scroll !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-height: 700px) {
  .fc-daygrid-day-number {
    padding-top: 0 !important;
    align-self: auto;
  }
}

.fc-day-today {
  margin-top: 100px !important;
  border-radius: 20px !important;
  background-color: transparent !important;
  height: 80% !important;
}

.fc-highlight {
  margin: 8px;
  border-radius: 20px;
  background-color: transparent !important;
  /*background-color: #F9FAFB !important;*/
}

.fc td {
  padding: 0;
  vertical-align: middle !important;
}
.fc-daygrid-day-frame:hover {
  .fc-daygrid-day-bg {
    z-index: -1;
    transition: 150ms;
    margin: 8px;
    position: absolute;
    inset: 0px;
    border-radius: 20px;
    background-color: #f9fafb !important;
    /*background-color: #F9FAFB !important;*/
  }
}

#calendar td {
  vertical-align: middle !important;
}

.fc {
  border: 1px solid #eaecf0;
  border-radius: 8px !important;
  overflow: hidden;
  text-align: center !important;

  a {
    text-decoration: none;
  }

  .fc-toolbar-title {
    color: #344054;
    margin: 0 !important;
    font-size: 16px !important;
  }

  .fc-button {
    padding: 0.275em 0.475em;
    font-size: 0.95em;
    line-height: 1.7;
    background-color: #e8e1e1;
    border-color: white;
  }

  .fc-toolbar.fc-header-toolbar {
    -moz-border-radius-topleft: 8px;
    -moz-border-radius-topright: 8px;
    margin-bottom: 0em !important;
    padding: 10px 15px !important;
    background-color: transparent;

    .fc-button {
      color: #667085;
      background-color: transparent;
      border-color: transparent;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }

  &.fc-theme-standard {
    table {
      border-collapse: collapse;
      /* Shadow/xl */
      border-radius: 8px !important;

      th {
        font-size: 14px;
        text-decoration: none;
        line-height: 20px;
        border: none;
        background-color: transparent;

        a.fc-col-header-cell-cushion {
          color: black;
          font-weight: normal;
          padding: 10px !important;
        }
      }

      td {
        border: none;
      }

      table {
        td {
          background-color: transparent;
          color: inherit;
          font-size: 14px;
          text-decoration: none;
          padding: 0;

          a {
            color: inherit;
            font-weight: normal;
          }

          .fc-daygrid-more-link {
            font-size: 10px;
          }

          .fc-daygrid-day-events {
            margin-top: -8px;
          }

          &:first-child {
            border-left: none;
          }

          &:last-child {
            border-right: none;
          }
        }

        tr:first-child {
          td {
            border-bottom: none;
          }
        }

        tr:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .fc.fc-theme-standard table table td .fc-daygrid-more-link {
    height: 8px;
    width: 8px;
    border-radius: 8px;
    text-indent: 15px;
    overflow: hidden;
    display: block;
  }
}

.form-floating > .form-control[type="file"] {
  padding-top: 1.75rem;
}
// Helpers from bootstrap 5.1
.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.ck-editor {
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.ck-sticky-panel__content {
  border-top-left-radius: 8px !important;
}
.ck-rounded-corners {
  border-radius: 8px !important;
}
.ck-editor__main {
  .ck-rounded-corners {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
}
.ck-toolbar_grouping {
  border: 0 !important;
  border-radius: 10px !important;
}

.ck-editor__editable {
  min-height: 140px;
  min-width: 100%;
  --ck-border-radius: 5px;
  --ck-color-base-border: #ffffff;
}

.ck-button_with-text {
  font-family: "Inter", sans-serif !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.ck-toolbar_grouping {
  margin: 0% !important;
  padding: 0% !important;
  width: 100% !important;
}

.ck-toolbar__items {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  padding: 10px !important;
  background-color: #f2f4f7 !important;
}

.ck-button__icon {
  color: #98a2b3 !important;
}

.ck-dropdown__button {
  font-weight: 500px !important;
  background-color: white !important;
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px !important;
}

.ck-focused {
  border: none !important;
}

.ck.ck-toolbar {
  border: 0;
  --ck-color-toolbar-border: #ffffff;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-family: var(--font-family);
}

.cdk-overlay-backdrop {
  z-index: 998 !important;
  background: rgba(0, 0, 0, 0.4) !important;
  backdrop-filter: blur(5px) !important;
  transition: none !important;
}

.mat-dialog-container {
  border-radius: 20px !important;
}

app-menu-item.col-6 {
  margin-bottom: 10vw;

  .navcard {
    width: 30vw;
    height: 30vw;
  }
}

app-menu-item.col-6 {
  margin-bottom: calc(15vw - 30px);

  .navcard {
    width: calc(30vw + 20px);
    height: calc(30vw + 20px);
  }
}

app-menu-item.col-4 {
  margin-bottom: calc(10vw - 30px);

  .navcard {
    width: calc(20vw + 30px);
    height: calc(20vw + 30px);
  }
}

app-plan-status-form .ng-select .ng-select-container .ng-value-container {
  margin-top: 0 !important;

  .ng-value {
    border: none !important;
    align-self: center;
  }
}

.orange-dark {
  color: var(--primary-color);
  background-color: var(--primary-color);
}

.btn-lg {
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  border-radius: 8px;
  gap: 8px;
  height: 36px;
  width: 120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .text-btn-add {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
}

.emailHover {
  color: var(--primary-color);
  text-decoration: none;
  background-color: #ff713e2a;
  padding: 2px 4px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  margin: -2px;

  &:hover::after {
    content: attr(data-email);
    position: absolute;
    bottom: 110%;
    left: 0;
    background-color: #f9f9f9;
    padding: 3px 5px;
    border: 1px solid #ccc;
    border-radius: 8px;
    white-space: pre;
    color: #333;
    font-size: 12px;
    z-index: 1000;
    border: 1px solid #888;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
}

.cdk-overlay-container,
.modal {
  z-index: 10000;
}

.border-radius-8 {
  border-radius: 8px !important;
}

.mat-mdc-menu-panel {
  border-radius: 8px !important;
  background-color: white !important;
  box-shadow: 3px 3px 15px 1px rgba(204, 204, 204, 1) !important;
  .mat-mdc-menu-content {
    padding: 0 !important;
  }
  .mat-mdc-menu-item {
    padding: 3px 7px !important;
    min-height: 2rem !important;
    width: 100% !important;
    transition: 0.2s background-color !important;
    &:hover {
      background-color: #f5f5f5 !important;
    }
    &:first-child {
      padding-top: 7px !important;
    }
    &:last-child {
      padding-bottom: 7px !important;
    }
  }
}

::ng-deep .border-radius-8 {
  border-radius: 8px !important;
  .mat-mdc-menu-content {
    padding: 0;
  }
}

.mobile-add-button {
  position: fixed;
  bottom: 64px;
  right: 0;

  button {
    border-radius: 50% 0 0 0;
    width: 3.5rem !important;
    height: 3.5rem;
  }
}

.pointer {
  cursor: pointer;
}

pre {
  white-space: pre-wrap;
  font-family: inherit;
}

// NEW STYLES

::-webkit-scrollbar-track {
  background: none !important;
}

// :root {
//   --primary-color: #ff4405;
//   --primary-color-33: #ff440533;
//   --primary-color-77: #ff440577;
//   --secondary-color: #ff4405;
//   --background-color: #ffffff;
//   --font-family: "Inter", sans-serif;

//
//   --p-primary-500: "#ff4405";
//   --p-primary-600: "#ff440577";
// }

:root {
  --primary-color: #ececec;
  --primary-color-33: #ececec;
  --primary-color-77: #ececec;
  --secondary-color: #ececec;
  --font-family: "Inter", sans-serif;
  --background-color: #ffffff;
  //primeng
  --p-primary-500: #ececec;
  --p-primary-600: #ececec;

  //nybolig special
  --start-page-color: #ff4405;
}

.list-card {
  background: white !important;
  background-color: white !important;
  box-shadow: 2px 2px 7px 0px rgb(225, 225, 225) !important;
  border: none !important;
  border-radius: 12px !important;
}

* {
  font-family: var(--font-family) !important;
}

.pi {
  font-family: "primeicons" !important;
}

@font-face {
  font-family: "Franklin Gothic";
  src: url("/assets/fonts/franklin-gothic/frabk.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Franklin Gothic";
  src: url("/assets/fonts/franklin-gothic/frabkit.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Franklin Gothic";
  src: url("/assets/fonts/franklin-gothic/framd.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Franklin Gothic";
  src: url("/assets/fonts/franklin-gothic/fradm.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Franklin Gothic";
  src: url("/assets/fonts/franklin-gothic/fradmcn.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Franklin Gothic";
  src: url("/assets/fonts/franklin-gothic/fradmit.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Franklin Gothic";
  src: url("/assets/fonts/franklin-gothic/frahv.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Franklin Gothic";
  src: url("/assets/fonts/franklin-gothic/frahvit.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Franklin Gothic";
  src: url("/assets/fonts/franklin-gothic/framdcn.ttf") format("truetype");
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: "Franklin Gothic";
  src: url("/assets/fonts/franklin-gothic/framdit.ttf") format("truetype");
  font-weight: medium;
  font-style: italic;
}
