.example-box3Q {
  margin-left: 15px;
  height: 10%;
  margin-top: 15px;
  display: inline-block;
  text-align: center;
  width: 100%;
  cursor: move;
}

.example-box2 {
  margin-left: 15px;
  min-height: 10%;
  margin-top: 15px;
  border-bottom: solid 1px #ccc;
  display: inline-block;
  text-align: center;
  width: 100%;
  cursor: move;
  background: rgb(255, 255, 255);
}

.example-list {
  width: 100%;
  max-width: 100%;
  border: solid 1px #ccc;
  min-height: 60px;
  height: 100%;
  max-height: 100%;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.example-box {
  margin-left: 15px;
  height: 10%;
  margin-top: 15px;
  border-bottom: solid 1px #ccc;
  display: inline-block;
  text-align: center;
  width: 100%;
  cursor: move;
  background: rgb(184, 184, 184);
  font-size: 14px;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
  border: none;
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-custom-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.borders {
  border: 1.5px dashed rgb(255, 68, 5);
}

.border1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Border with a width larger than the container */
  box-sizing: border-box;
}

.rubashka {
  background-color: rgb(255, 255, 255) !important;
  background-position-x: 0px, 0px, 10px, -10px !important;
  background-position-y: 0px, 10px, -10px, 0px !important;
  background-size: 20px 20px, 20px 20px, 20px 20px, 20px 20px !important;
  background-image: linear-gradient(
      45deg,
      rgb(235, 235, 235) 25%,
      transparent 25%
    ),
    linear-gradient(-45deg, rgb(235, 235, 235) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, rgb(235, 235, 235) 75%),
    linear-gradient(-45deg, transparent 75%, rgb(235, 235, 235) 75%);
}
